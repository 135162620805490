import React from 'react';
import { Field } from 'formik';
import classnames from "classnames";
import "./forms.scss";

const TextField = (props) => {
    const { label, name, required, type, ...rest } = props;

    const ifRequired = () => {
        if (required == true) {
            return <span className="required">*</span>
        }
    }

    return (
        <Field name={name} {...rest} >
            {({ field, meta }) => (
                <div className={classnames("partie-form__field-container", {
                    'partie-form__field-container--error': meta.error && meta.touched
                })}>
                    <label className="partie-form__label" htmlFor={name}>
                        {label} {ifRequired()}
                    </label>
                    <input
                        className="partie-form__field"
                        id={name}
                        type={type}
                        onChange={field.onChange}
                    />
                    {(meta.error && meta.touched) && (
                        <span className="partie-form__field-caption">{meta.error}</span>
                    )}
                </div>
            )}
        </Field>
    );
};

export default TextField;